<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <a-form-item class="column is-12" :label="$t('Out of Order Name')" name="name">
      <a-input v-model:value="form.name" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Description')" name="description">
      <a-input v-model:value="form.description" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Upload pictures')" name="images">
      <a-upload
        name="file"
        accept="image/png, image/jpeg"
        list-type="picture-card"
        :file-list="fileList"
        :action="addImagesAction"
        :multiple="true"
        :before-upload="handleBeforeUpload"
        :remove="handleRemove"
        @change="handleImageChange"
      >
        <a-button type="primary" :size="size">
          {{ $t('Browse File') }}
        </a-button>
      </a-upload>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Property')" name="propertyId">
      <InputProperty v-model:value="form.propertyId" @change="validField('propertyId')" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Name')" name="roomId">
      <InputRoom
        v-model:value="form.roomId"
        :property-id="form.propertyId"
        @change="validField('roomId')"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Checkout Date')" name="checkoutDate">
      <a-date-picker
        v-model:value="form.checkoutDate"
        :value-format="dateFormat"
        :size="size"
        style="width: 100%"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Status')" name="status">
      <a-select
        v-model:value="form.status"
        :placeholder="$t('Please select')"
        style="min-width: 200px"
        :size="size"
      >
        <a-select-option v-for="o in statusOptions" :key="o.label" :value="o.value">
          {{ $t(o.value) }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <div class="column is-12 is-flex is-justify-content-space-between m-t-4x">
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-3x"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
      <DelOutOfOrder
        v-if="value && value.id"
        v-permission="['modify-out-of-order']"
        :record="value"
        @delete="$router.push({ path: '/out-of-order' })"
      >
        <template #handler="{ show }">
          <a-button :size="size" type="danger" style="min-width: 155px" @click="show">
            {{ $t('Delete') }}
          </a-button>
        </template>
      </DelOutOfOrder>
    </div>
  </a-form>
</template>

<i18n>
{
"en": {
"Out of Order Name": "Out of Order Name",
"Description": "Description",
"Upload pictures": "Upload pictures",
"Browse File": "Browse File",
"Property": "Property",
"Room Name": "Room Name",
"Checkout Date": "Checkout Date",
"Status": "Status",
"Save": "Save",
"Name is required": "Name is required",
"Status is required": "Status is required",
"Property is required": "Property is required"
},
"ja": {
"Out of Order Name": "使用不可 アイテム名",
"Description": "詳細",
"Upload pictures": "写真アップロード",
"Browse File": "閲覧ファイル",
"Property": "物件",
"Room Name": "部屋番号",
"Checkout Date": "チェックアウト日",
"Status": "ステータス",
"Save": "保存",
"Name is required": "名は必須です",
"Status is required": "ステータスは必須です",
"Property is required": "物件は必須です"
}
}
</i18n>

<script>
import DelOutOfOrder from '@/views/out-of-order/components/DelOutOfOrder';
import InputProperty from '@/components/InputProperty';
import InputRoom from '@/components/InputRoom';
import { STATUS } from '@/config/out-of-order';
import FileUploader from '@/components/mixins/FileUploader';

export default {
  name: 'EditForm',
  components: {
    DelOutOfOrder,
    InputRoom,
    InputProperty,
  },
  mixins: [FileUploader],
  props: {
    isSubmitting: {
      type: Boolean,
    },
    value: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ['submit', 'close', 'cancel', 'image-removed'],
  data() {
    return {
      dateFormat: 'YYYY-MM-DD',
      form: {
        name: undefined,
        description: undefined,
        images: [],
        propertyId: undefined,
        roomId: undefined,
        checkoutDate: undefined,
        status: undefined,
      },
      fileList: [],
      rules: {
        name: [
          {
            required: true,
            message: this.$t('Name is required'),
            trigger: ['change', 'blur'],
          },
        ],
        status: [
          {
            required: true,
            message: this.$t('Status is required'),
            trigger: ['change', 'blur'],
          },
        ],
        propertyId: [
          {
            required: true,
            message: this.$t('Property is required'),
            trigger: 'change',
          },
        ],
      },
      size: 'large',
      statusOptions: STATUS,
    };
  },
  computed: {
    addImagesAction() {
      if (!this.value) {
        return '';
      }

      return `${process.env.VUE_APP_API_URL}/out-of-order/${this.value.id}/images`;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          if (nv && nv.images && nv.images.length) {
            this.fileList = nv.images;
          }
          const keys = Object.keys(nv);
          keys.forEach((k) => {
            this.form[k] = nv[k];
          });
        }
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.$emit('submit', { ...this.form });
      } catch (e) {
        console.log(e);
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();

      newFileList.splice(index, 1);
      this.fileList = newFileList;

      this.removeFromImages(file);

      this.$emit('image-removed', file);
      // prevent default removal
      return false;
    },
    validField(f) {
      setTimeout(() => {
        this.$refs.form.validateFields([f]);
      }, 300);
    },
  },
};
</script>
