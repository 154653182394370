<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Out of Order') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <SearchForm @filter="fetchData" />
          </div>
          <div v-permission="['add-out-of-order']" class="column is-narrow">
            <router-link
              class="ant-btn ant-btn-lg ant-btn-primary"
              :to="{ name: 'out-of-order-add' }"
            >
              {{ $t('New Out of Order') }}
            </router-link>
          </div>
        </div>
        <a-table
          row-key="id"
          :columns="columns"
          :data-source="list"
          :loading="isFetching"
          :custom-row="rowClicked"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #id="{ text }">
            {{ text }}
          </template>
          <template #name="{ record }">
            {{ record.name }}
          </template>
          <template #property="{ record }">
            {{ record.property ? record.property.abbreviation : '' }}
          </template>
          <template #room="{ record }">
            {{ record.room ? record.room.name : '' }}
          </template>
          <template #reportedTime="{ record }">
            {{ $filters.date(record.createdAt, { format: $variables.datetimeFormat }) }}
          </template>
          <template #status="{ record }">
            {{ $t(record.status) }}
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Out of Order": "Out of Order",
"New Out of Order": "New Out of Order",
"Out of Order Name": "Out of Order Name",
"Property": "Property",
"Room Name": "Room Name",
"Reported time": "Reported time",
"Status": "Status"
},
"ja": {
"Out of Order": "使用不可",
"New Out of Order": "新規使用不可",
"Out of Order Name": "使用不可 アイテム名",
"Property": "物件",
"Room Name": "部屋番号",
"Reported time": "報告時間",
"Status": "ステータス"
}
}
</i18n>

<script>
import SearchForm from '@/views/out-of-order/components/SearchForm';
import list from '@/views/out-of-order/mixins/list';

export default {
  name: 'OutOfOrder',
  components: {
    SearchForm,
  },
  mixins: [list],
  data() {
    return {
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Out of Order Name'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Property'),
          dataIndex: 'property',
          key: 'property',
          slots: { customRender: 'property' },
        },
        {
          title: this.$t('Room Name'),
          dataIndex: 'room',
          key: 'room',
          slots: { customRender: 'room' },
        },
        {
          title: this.$t('Reported time'),
          dataIndex: 'reportedTime',
          slots: { customRender: 'reportedTime' },
        },
        {
          title: this.$t('Status'),
          key: 'isActive',
          dataIndex: 'isActive',
          slots: { customRender: 'status' },
        },
        {
          title: ' ',
          key: 'action',
          width: '100px',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    rowClicked(record) {
      return {
        onClick: () => {
          this.$router.push({ name: 'out-of-order-edit', params: { id: record.id } });
        },
      };
    },
  },
};
</script>
