<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="has-text-centered is-3 title">
            {{ $t('Add Out of Order') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mobi">
        <EditForm ref="form" :is-submitting="isSubmitting" :value="form" @submit="handleSubmit" />
        <a-alert v-if="outOfOrder" type="success">
          <template #message>
            <span v-html="$t('added', { name: outOfOrder.name })" />
            <a @click="handleViewClick">
              {{ $t('view') }}
            </a>
            .
          </template>
        </a-alert>
        <a-alert v-if="error" :message="error" type="error" />
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Add Out of Order": "Add Out of Order",
"added": "Out of order <strong>{name}</strong> added,",
"view": "View out of order list"
},
"ja": {
"Add Out of Order": "使用不可を追加",
"added": "使用不可 <strong>{name}</strong> が追加されました。",
"view": "使用不可リストを閲覧"
}
}
</i18n>

<script>
import EditForm from '@/views/out-of-order/components/EditForm';
import { getResponseError } from '@/utils/util';

export default {
  name: 'Add',
  components: { EditForm },
  beforeRouteEnter: (to, from, next) => {
    next((vm) => {
      if (from.name === 'housekeeper-task-out-of-order') {
        vm.fromHkTask = true;
      }
    });
  },
  data() {
    return {
      fromHkTask: false,
      outOfOrder: undefined,
      isSubmitting: false,
      error: '',
      form: {
        propertyId: undefined,
        roomId: undefined,
        checkoutDate: undefined,
      },
    };
  },
  watch: {
    $route(route) {
      if (route.name === 'out-of-order-add') {
        this.initData();
      }
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.$route.query.propertyId) {
        this.form.propertyId = this.$route.query.propertyId;
      }
      if (this.$route.query.roomId) {
        this.form.roomId = parseInt(this.$route.query.roomId, 10);
      }
      if (this.$route.query.checkoutDate) {
        this.form.checkoutDate = this.$route.query.checkoutDate;
      }
    },
    async handleSubmit(form) {
      try {
        this.error = '';
        this.isSubmitting = true;
        this.outOfOrder = undefined;

        const { images, ...payload } = form;
        const { data } = await this.$store.dispatch('out-of-order/add', payload);

        if (images.length) {
          const imgPayload = {
            id: data.id,
            file: images,
          };

          await this.$store.dispatch('out-of-order/addImages', imgPayload);
        }

        this.isSubmitting = false;
        this.outOfOrder = data;
        this.$refs.form.resetFields();
      } catch (e) {
        this.isSubmitting = false;
        this.error = getResponseError(e);
      }
    },
    handleViewClick() {
      if (this.fromHkTask) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: 'out-of-order' });
      }
    },
  },
};
</script>
