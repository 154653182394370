<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="has-text-centered is-3 title">
            {{ $t('Edit Out of Order') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mobi">
        <EditForm
          ref="form"
          :value="outOfOrder"
          :is-submitting="isSubmitting"
          @submit="handleSubmit"
          @image-removed="handleImageRemoved"
        />
        <a-alert v-if="submitted" type="success">
          <template #message>
            <span v-html="$t('updated', { name: outOfOrder.name })" />
            <router-link to="/out-of-order">
              {{ $t('view') }}
            </router-link>
            .
          </template>
        </a-alert>
        <a-alert v-if="error" :message="error" type="error" />
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Edit Out of Order": "Edit Out of Order",
"updated": "Out of order <strong>{name}</strong> updated,",
"view": "View out of order list"
},
"ja": {
"Edit Out of Order": "使用不可を編集",
"updated": "使用不可 <strong>{name}</strong> が更新されました。",
"view": "使用不可リストを閲覧"
}
}
</i18n>

<script>
import EditForm from '@/views/out-of-order/components/EditForm';
import { appendImageUrl, getResponseError } from '@/utils/util';

export default {
  name: 'Add',
  components: { EditForm },
  beforeRouteEnter: (to, from, next) => {
    next((vm) => {
      if (from.name === 'housekeeper-task-out-of-order') {
        vm.fromHkTask = true;
      }
    });
  },
  data() {
    return {
      fromHkTask: false,
      outOfOrder: undefined,
      isSubmitting: false,
      error: '',
      submitted: false,
      removedImage: [],
    };
  },
  watch: {
    $route(route) {
      if (route.name === 'out-of-order-edit') this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('out-of-order/get', this.$route.params.id)
        .then((resp) => {
          this.outOfOrder = {
            id: resp.id,
            name: resp.name,
            description: resp.description,
            roomId: resp.roomId,
            propertyId: resp.propertyId,
            checkoutDate: resp.checkoutDate,
            status: resp.status,
            images: appendImageUrl(resp.images),
          };
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    async handleSubmit(data) {
      try {
        const { id } = this.outOfOrder;
        this.error = '';
        this.isSubmitting = true;
        this.submitted = false;
        this.outOfOrder = undefined;

        const { images, ...rest } = data;
        const resp = await this.$store.dispatch('out-of-order/update', { id, ...rest });

        if (images.length) {
          const imgPayload = { id, file: images };

          await this.$store.dispatch('out-of-order/addImages', imgPayload);
        }

        if (this.removedImage.length) {
          await Promise.all(
            this.removedImage.map(async (img) => {
              if (img.id) {
                await this.$store.dispatch('out-of-order/deleteImage', { id, imageId: img.id });
              }
            }),
          );
        }

        this.$message.success('Update success');
        this.isSubmitting = false;
        this.outOfOrder = {
          id: resp.id,
          name: resp.name,
          description: resp.description,
          roomId: resp.roomId,
          propertyId: resp.propertyId,
          checkoutDate: resp.checkoutDate,
          status: resp.status,
          images: resp.images,
        };

        this.$refs.form.resetFields();
        this.submitted = true;
        if (this.fromHkTask) {
          this.$router.go(-1);
        } else {
          this.$router.push({ name: 'out-of-order' });
        }
      } catch (e) {
        this.isSubmitting = false;
        this.error = getResponseError(e);
      }
    },
    handleImageRemoved(file) {
      this.removedImage.push(file);
    },
  },
};
</script>
