<template>
  <a-form ref="form" :model="form" :rules="rulesRole" class="columns is-mini-gap is-multiline">
    <a-form-item class="column is-12" :label="$t('Summary')" name="summary">
      <a-select
        v-model:value="form.summary"
        :placeholder="$t('Please select')"
        style="min-width: 200px"
        :size="size"
        @change="getPropertyFee"
      >
        <a-select-option v-for="(v, key) in summaryOptions" :key="key" :value="v">
          {{ $t(v) }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item
      v-role="[...reportRoles, 'HC']"
      class="column is-12"
      :label="$t('Owner Bill')"
      name="ownerBill"
    >
      <a-input-number v-model:value="form.ownerBill" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Working day')" name="workingDate">
      <a-date-picker
        v-model:value="form.workingDate"
        size="large"
        style="width: 100%"
        :format="dateFormat"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Property')" name="propertyId">
      <InputProperty
        v-model:value="form.propertyId"
        :disabled="!form.summary"
        @change="handlePropertyChange"
      />
    </a-form-item>
    <a-form-item class="column is-8" :label="$t('Cost')" name="cost">
      <a-input-number v-model:value="form.cost" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-4" :label="$t('Currency')" name="currency">
      <InputCurrency v-model:valueModel="form.currency" @change="validField('currency')" />
    </a-form-item>
    <a-form-item
      v-show="showReceipt"
      class="column is-12"
      :label="$t('Upload receipt')"
      name="receipt"
    >
      <a-upload
        v-model:file-list="fileList"
        name="file"
        list-type="picture-card"
        :multiple="false"
        :before-upload="handleBeforeUpload"
        :remove="handleRemove"
        @change="handleImageChange"
      >
        <a-button type="primary" :size="size">
          {{ $t('Browse File') }}
        </a-button>
      </a-upload>
    </a-form-item>
    <a-form-item
      v-role="[...reportRoles, 'HC']"
      class="column is-12"
      :label="$t('Remarks')"
      name="remarks"
    >
      <a-input v-model:value="form.remarks" :size="size" />
    </a-form-item>
    <a-form-item v-role="reportRoles" class="column is-12" :label="$t('Bearer')" name="bearer">
      <a-select
        v-model:value="form.bearer"
        placeholder="Please select"
        style="min-width: 200px"
        :size="size"
      >
        <a-select-option v-for="(v, key) in bearerOptions" :key="key" :value="v">
          {{ $t(v) }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item
      v-role="reportRoles"
      class="column is-12"
      :label="$t('Payoff Method')"
      name="payoffMethod"
    >
      <a-select
        v-model:value="form.payoffMethod"
        placeholder="Please select"
        style="min-width: 200px"
        :size="size"
      >
        <a-select-option v-for="(v, key) in payoffMethodOptions" :key="key" :value="v">
          {{ $t(v) }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item v-role="reportRoles" class="column is-12" :label="$t('Tax Rate')" name="taxRate">
      <a-select
        v-model:value="form.taxRate"
        placeholder="Please select"
        style="min-width: 200px"
        :size="size"
      >
        <a-select-option v-for="(o, key) in taxRateOptions" :key="key" :value="o">
          {{ o }}%
        </a-select-option>
      </a-select>
    </a-form-item>
    <div v-if="value && value.status" class="column is-12">
      <p class="m-t-3x">
        {{ $t('Status') }}
      </p>
      <p class="has-text-grey">
        {{ $t(value.status) }}
      </p>
    </div>
    <div v-if="value && value.remarks" class="column is-12">
      <p class="m-t-3x">
        {{ $t('Remarks') }}:
      </p>
      <p class="has-text-grey">
        {{ value.remarks }}
      </p>
    </div>

    <div class="column is-12 m-t-4x">
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-3x"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
      <template v-if="isReport">
        <Modal>
          <template #default="{ hide }">
            <ExpenseRejectForm
              :expenses="[value]"
              @close="
                () => {
                  hide();
                  goBack();
                }
              "
              @cancel="hide"
            />
          </template>
          <template #handler="{ show }">
            <a
              class="ant-btn ant-btn-danger ant-btn-lg btn-reject m-r-3x"
              style="min-width: 155px"
              @click.prevent.stop="show"
            >
              {{ $t('Reject') }}</a>
          </template>
        </Modal>
        <Modal>
          <template #default="{ hide }">
            <ExpenseApproveForm
              :expenses="[value]"
              @close="
                () => {
                  hide();
                  goBack();
                }
              "
              @cancel="hide"
            />
          </template>
          <template #handler="{ show }">
            <a
              class="ant-btn ant-btn-lg ant-btn-primary btn-approve"
              style="min-width: 155px"
              @click.prevent.stop="show"
            >
              {{ $t('Approve') }}</a>
          </template>
        </Modal>
      </template>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "Summary": "Summary",
    "Working day": "Working day",
    "date range": "date range",
    "Property": "Property",
    "Cost": "Cost",
    "Currency": "Currency",
    "Upload pictures": "Upload pictures",
    "Browse File": "Browse File",
    "Remarks": "Remarks",
    "Status": "Status",
    "Bearer": "Bearer",
    "Payoff Method": "Payoff Method",
    "Tax Rate": "Tax Rate",
    "Owner Bill": "Owner Bill",
    "created": "created",
    "waiting approval": "waiting approval",
    "approved": "approved",
    "rejected": "rejected",
    "payment in process": "payment in process",
    "cleaning fee": "cleaning fee",
    "advance payment fee": "advance payment fee",
    "emergency assistant fee": "emergency assistant fee",
    "adjustment fee": "adjustment fee",
    "RLS Burden": "RLS Burden",
    "Offset with Balance Reports": "Offset with Balance Reports",
    "Save": "Save",
    "Summary is required": "Summary is required",
    "Working day is required": "Working day is required",
    "Property is required": "Property is required",
    "Cost is required": "Cost is required",
    "Currency is required": "Currency is required",
    "Bearer is required": "Bearer is required",
    "Payoff method is required": "Payoff method is required",
    "Tax rate is required": "Tax rate is required",
    "Owner bill is required": "Owner bill is required",
    "Remarks cannot be longer than 54 characters": "Remarks cannot be longer than 54 characters"
  },
  "ja": {
    "Summary": "摘要",
    "Working day": "勤務日は",
    "date range": "日付範囲",
    "Property": "物件",
    "Cost": "金額",
    "Currency": "通貨",
    "Upload pictures": "写真アップロード",
    "Browse File": "閲覧ファイル",
    "Remarks": "備考",
    "Status": "ステータス",
    "Bearer": "負担者",
    "Payoff Method": "精算方法",
    "Tax Rate": "税率",
    "Owner Bill": "オーナー請求額",
    "created": "新規",
    "waiting approval": "未承認",
    "approved": "承認済み",
    "rejected": "却下",
    "payment in process": "支払い処理中",
    "cleaning fee": "清掃代",
    "advance payment fee": "駆けつけ対応",
    "emergency assistant fee": "立替金精算",
    "adjustment fee": "調整金",
    "RLS Burden": "当社負担",
    "Offset with Balance Reports": "収支報告書で相殺",
    "Save": "保存",
    "Summary is required": "摘要必須です",
    "Working days is required": "勤務日は必須です",
    "Property is required": "物件は必須です",
    "Cost is required": "金額必須です",
    "Currency is required": "通貨は必須です",
    "Bearer is required": "負担者は必須です",
    "Payoff method is required": "精算方法は必須です",
    "Tax rate is required": "税率は必須です",
    "Owner bill is required": "オーナー請求額は必須です",
    "Remarks cannot be longer than 54 characters": "備考は54文字以下でないといけません"
  }
}
</i18n>

<script>
import { mapGetters } from 'vuex';
import { uploadableImage } from '@/utils/util';
import InputProperty from '@/components/InputProperty';
import Modal from '@/components/Modal';
import ExpenseRejectForm from '@/views/reports/components/ExpenseRejectForm';
import ExpenseApproveForm from '@/views/reports/components/ExpenseApproveForm';
import InputCurrency from '@/components/InputCurrency';
import {
  STATUS, SUMMARY, BEARER, PAYOFF_METHOD, TAX_RATE,
} from '@/config/expense';
import { REPORTS } from '@/config/roles';

export default {
  name: 'EditForm',
  components: {
    InputCurrency,
    InputProperty,
    Modal,
    ExpenseRejectForm,
    ExpenseApproveForm,
  },
  props: {
    isSubmitting: {
      type: Boolean,
    },
    value: {
      type: Object,
      default() {
        return null;
      },
    },
    sourceRoute: {
      type: String,
      default: null,
    },
  },
  emits: ['submit', 'close', 'cancel', 'image-removed'],
  data() {
    return {
      dateFormat: 'YYYY-MM-DD',
      form: {
        userId: undefined,
        summary: undefined,
        ownerBill: 0,
        workingDate: '',
        propertyId: undefined,
        cost: undefined,
        currency: undefined,
        receipt: undefined,
        status: undefined,
        remarks: undefined,
        bearer: undefined,
        payoffMethod: undefined,
        taxRate: undefined,
      },
      fileList: [],
      rules: {
        summary: [
          {
            required: true,
            message: this.$t('Summary is required'),
            trigger: ['change', 'blur'],
          },
        ],
        workingDate: [
          {
            required: true,
            type: 'date',
            message: this.$t('Working day is required'),
            trigger: ['change'],
          },
        ],
        propertyId: [
          {
            required: true,
            message: this.$t('Property is required'),
            trigger: 'change',
          },
        ],
        cost: [
          {
            required: true,
            type: 'number',
            message: this.$t('Cost is required'),
            trigger: ['change', 'blur'],
          },
        ],
        currency: [
          {
            required: true,
            message: this.$t('Currency is required'),
            trigger: 'change',
          },
        ],
        remarks: [
          {
            max: 54,
            type: 'string',
            message: this.$t('Remarks cannot be longer than 54 characters'),
            trigger: ['change', 'blur'],
          },
        ],
        bearer: [
          {
            required: true,
            message: this.$t('Bearer is required'),
            trigger: ['change', 'blur'],
          },
        ],
        payoffMethod: [
          {
            required: true,
            message: this.$t('Payoff method is required'),
            trigger: ['change', 'blur'],
          },
        ],
        taxRate: [
          {
            required: true,
            type: 'number',
            message: this.$t('Tax rate is required'),
            trigger: ['change', 'blur'],
          },
        ],
        ownerBill: [
          {
            validator: (rule, value) => new Promise((resolve, reject) => {
              if (
                Number.isNaN(parseInt(value, 10))
                  && this.form.summary === SUMMARY.CLEANING_FEE
              ) {
                reject(this.$t('Owner bill is required'));
              } else {
                resolve();
              }
            }),
            trigger: ['change', 'blur'],
          },
        ],
      },
      size: 'large',
      statusOptions: STATUS,
      summaryOptions: SUMMARY,
      bearerOptions: BEARER,
      payoffMethodOptions: PAYOFF_METHOD,
      taxRateOptions: TAX_RATE,
    };
  },
  computed: {
    ...mapGetters({
      roles: 'roles',
    }),
    reportRoles() {
      return REPORTS;
    },
    showReceipt() {
      return this.form.summary === SUMMARY.ADVANCE_PAYMENT_FEE;
    },
    isReport() {
      return this.sourceRoute === 'reports-expenses';
    },
    rulesRole() {
      const rules = { ...this.rules };
      if (this.roles.includes('HKP') || this.roles.includes('HC')) {
        delete rules.bearer;
        delete rules.payoffMethod;
        delete rules.taxRate;

        if (this.roles.includes('HKP')) {
          delete rules.remarks;
          delete rules.ownerBill;
        }
      }

      return rules;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          const keys = Object.keys(nv);
          keys.forEach((k) => {
            this.form[k] = nv[k];
          });

          this.form.workingDate = nv.workDateStart;

          if (nv && nv.receipt && typeof nv.receipt === 'string') {
            this.fileList = [uploadableImage({ path: nv.receipt })];
          }
        }
      },
    },
  },
  methods: {
    handlePropertyChange(value) {
      this.form.propertyId = value ? value.newPropertyCode : null;
      this.validField('propertyId');
      this.getPropertyFee();
    },
    getPropertyFee() {
      const { propertyId, summary } = this.form;

      if (propertyId
        && (summary === SUMMARY.CLEANING_FEE
          || summary === SUMMARY.EMERGENCY_ASSISTANT_FEE)
      ) {
        this.$store.dispatch('properties/getFee', { id: propertyId })
          .then((response) => {
            const { cleaningFee, currency, emergencyAssistantFee } = response;

            if (summary === SUMMARY.CLEANING_FEE) {
              this.form.cost = cleaningFee || this.form.cost;
            }

            if (summary === SUMMARY.EMERGENCY_ASSISTANT_FEE) {
              this.form.cost = emergencyAssistantFee || this.form.cost;
            }

            this.form.currency = currency || this.form.currency;

            this.validField('cost');
            this.validField('currency');
          });
      }
    },
    goBack() {
      this.$router.push({ name: this.sourceRoute });
    },
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        const { workingDate, ...rest } = this.form;
        this.$emit('submit', {
          ...rest,
          workDateStart: workingDate,
          workDateEnd: workingDate,
        });
      } catch (e) {
        console.log(e);
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();

      newFileList.splice(index, 1);
      this.fileList = newFileList;

      this.form.receipt = '';
      this.$emit('image-removed', file);

      return false;
    },
    handleBeforeUpload(file) {
      this.fileList = [file];
      this.form.receipt = file;
      return false;
    },
    handleImageChange(info) {
      const fileList = [...info.fileList];
      this.fileList = fileList.slice(-1);
    },
    validField(f) {
      setTimeout(() => {
        this.$refs.form.validateFields([f]);
      }, 300);
    },
  },
};
</script>
