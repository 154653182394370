<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="has-text-centered is-3 title">
            {{ $t('Add New Expense') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mobi">
        <EditForm ref="form" :is-submitting="isSubmitting" :value="form" @submit="handleSubmit" />
        <a-alert v-if="expense" type="success">
          <template #message>
            <span v-html="$t('expense-added', { summary: expense.summary })" />
            <router-link to="/expenses">
              {{ $t('View expenses') }}
            </router-link>
            .
          </template>
        </a-alert>
        <a-alert v-if="error" :message="error" type="error" />
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Add New Expense": "Add New Expense",
"expense-added": "expense <strong>{summary}</strong> added,",
"View expenses": "View expenses"
},
"ja": {
"Add New Expense": "新規経費を追加",
"expense-added": "経費が<strong>{summary}</strong>追加されました。",
"View expenses": "経費を見る"
}
}
</i18n>

<script>
import { getResponseError } from '@/utils/util';
import EditForm from '@/views/expenses/components/EditForm';

export default {
  name: 'Add',
  components: { EditForm },
  data() {
    return {
      expense: undefined,
      isSubmitting: false,
      error: '',
    };
  },
  methods: {
    async handleSubmit(data) {
      try {
        this.isSubmitting = true;
        this.expense = undefined;
        this.error = '';

        const { receipt, ...rest } = data;
        const res = await this.$store.dispatch('expenses/add', rest);

        if (receipt) {
          const receiptPayload = { id: res.data.id, file: receipt };

          await this.$store.dispatch('expenses/uploadReceipt', receiptPayload);
        }

        this.isSubmitting = false;
        this.expense = data;
        this.$refs.form.resetFields();
      } catch (err) {
        this.isSubmitting = false;
        this.error = getResponseError(err);
      }
    },
  },
};
</script>
