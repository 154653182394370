<template>
  <Delete
    action-handler="out-of-order/delete"
    :payload-value="record.id"
    :title="$t('title')"
    :description="$t('desc', { name: record.name })"
    :success-title="$t('deleted-title')"
    :success-description="$t('deleted-desc', { name: record.name })"
    @close="$emit('delete')"
  >
    <template #handler="{ show }">
      <slot name="handler" :show="show" />
    </template>
  </Delete>
</template>

<i18n>
{
"en": {
"title": "Delete Out of order?",
"desc": "Are you sure you want to delete out of order <strong>{name}</strong> ?",
"deleted-title": "Out of order deleted",
"deleted-desc": "Out of order <strong>{name}</strong> has been removed."
},
"ja": {
"title": "使用不可を削除?",
"desc": "本当に <strong>{name}</strong> を削除しますか？",
"deleted-title": "使用不可削除完了",
"deleted-desc": "使用不可 <strong>{name}</strong> が削除されました"
}
}
</i18n>

<script>
import Delete from '@/components/PopupDelete';

export default {
  name: 'DeleteLostFounds',
  components: {
    Delete,
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete'],
};
</script>
